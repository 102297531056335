import React from "react"
import { Footer, SEO, Layout, Hero, Container } from "@components"

const SingleTableModel = ({ pageContext }) => {
  const { model, readme } = pageContext

  return (
    <>
      <Layout>
        <SEO
          title={`${model.ModelName} (DynamoDB Single Table Design Example)`}
          description="todo"
        />
        <Hero
          title={`${model.ModelName} (DynamoDB Single Table Design Example)`}
          subtitle={model.ModelMetadata.Description}
        />
        <div>
          <Container maxWidth={"90%"} style={{ padding: 0 }} padding="0">
            <iframe
              style={{ border: "1px #e5e5e5 solid" }}
              width="100%"
              height="1000px"
              src={`https://viewer.dynobase.dev/?modelUrl=https://raw.githubusercontent.com/Dynobase/single-table-models/main/${model.ModelName}/${model.ModelName}.json`}
            />
            <div dangerouslySetInnerHTML={{ __html: readme.content }}></div>
          </Container>
          <div style={{ marginBottom: "100px" }}></div>
          <Footer noMarginTop />
        </div>
      </Layout>
    </>
  )
}

export default SingleTableModel
